/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  buttonText: string;
  className: any;
  linkTo?: string;
}

export const ButtonPrimary = ({ buttonText, className, linkTo }: Props): JSX.Element => {
  return (
    <a href={linkTo} target="_blank" className={`button-primary ${className}`}>
      <div className="contact">{buttonText}</div>
    </a>
  );
};

ButtonPrimary.propTypes = {
  buttonText: PropTypes.string,
  linkTo: PropTypes.string
};
