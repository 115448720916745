/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  buttonText: string;
  className: any;
  linkTo?: any;
  opensImage?: boolean
}

export const ButtonSecondary = ({ buttonText, className, linkTo, opensImage }: Props): JSX.Element => {
  return (
    <a href={linkTo} target="_blank" className={`button-secondary ${className}`}>
      <div className="text-wrapper">{buttonText}</div>
    </a>
  );
};

ButtonSecondary.propTypes = {
  buttonText: PropTypes.string,
  linkTo: PropTypes.any,
  opensImage: PropTypes.bool
};
