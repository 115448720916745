/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  value: string;
  placeholder?: string;
  className: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormField = ({ value, placeholder, className, onChange }: Props): JSX.Element => {
  return (
    <input
      type="text"
      className={`form-field ${className} voornaam`}
      value={value}
      onChange={e => { onChange(e) }}
      placeholder={placeholder}
    />
  );
};

FormField.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
