import React, { useState } from "react";
import { ButtonPrimary } from "../../components/ButtonPrimary";
import { ButtonSecondary } from "../../components/ButtonSecondary";
import { FormField } from "../../components/FormField";
import "./style.css";

export const Mobile = (): JSX.Element => {
  const [voornaam, setVoornaam] = useState("");
  const [achternaam, setAchternaam] = useState("");
  const [mail, setMail] = useState("");
  const [bedrijfsnaam, setBedrijfsnaam] = useState("")

  const handleVoornaamChange = (e) => { setVoornaam(e.target.value); };
  const handleAchternaamChange = (e) => { setAchternaam(e.target.value); };
  const handleMailChange = (e) => { setMail(e.target.value); };
  const handleBedrijfsChange = (e) => { setBedrijfsnaam(e.target.value); };

  function isNotEmpty(str) {
    return str.trim().length > 0;
  }

  function submitForm() {
    if (isNotEmpty(voornaam) && isNotEmpty(achternaam) && isNotEmpty(mail)) {
      const emailBody = `
        Dag Oktay,
        
        Ik zou me graag willen aanmelden voor de nieuwsbrief:

        Naam: ${voornaam},
        Achternaam: ${achternaam},
        Email: ${mail},
        ${bedrijfsnaam ? `Bedrijfsnaam: ${bedrijfsnaam}` : ``}
      `;

      const subject = encodeURIComponent("Arrangement aanvragen");
      const body = encodeURIComponent(emailBody);
      window.open(`mailto:${'start@nextchaptercoaching.nl'}?subject=${subject}&body=${body}`, '_blank');
    }
  }

  return (
    <div className="mobile">
      <div className="main">
        <div className="div-wrapper">
          <div className="title-bar-content">
            <img className="title-bar-logo" alt="Title bar logo" src="/img/title-bar-logo.png" />
            <div className="title-bar-subtitle">
              <div className="div">PURPOSE DRIVEN COACHING</div>
            </div>
          </div>
        </div>
        <div className="about">
          <div className="about-picture">
            <div className="about-picture-wrapper">
              <div className="about-picture-2" />
            </div>
            <div className="about-picture-lines">
              <div className="about-picture-line" />
              <div className="about-picture-line" />
              <div className="about-picture-line" />
            </div>
          </div>
          <div className="about-content">
            <div className="about-text-area">
              <p className="p">
                Als business mentor begeleid ik ondernemers met het overwinnen van praktische obstakels om dichter bij
                dromen te komen. Wil je groeien met jouw bedrijf volgens jouw eigen pad maar mis je wat strategie, of
                wil je persoonlijke groeien? Mogelijk kan Oktay jou helpen.
              </p>
              <p className="text-wrapper-2">Neem contact op met Oktay of plan alvast een kennismakingsafspraak</p>
            </div>
            <div className="about-contact">
              <div className="about-button-area">
                <ButtonPrimary buttonText="LINKEDIN" className="design-component-instance-node" linkTo="https://www.linkedin.com/in/oktayertana/"/>
                <ButtonSecondary buttonText="PLANNEN" className="design-component-instance-node" linkTo="https://calendly.com/start-tnc"/>
              </div>
              <div className="text-wrapper-3">+31657554000 | start@nextchaptercoaching.nl</div>
            </div>
          </div>
        </div>
        <div className="nieuwsbrief">
          <div className="div-2">
            <div className="div-3">
              <div className="nieuwsbrief-2">NIEUWSBRIEF</div>
              <p className="text-wrapper-4">
                Meld je aan voor de The Next Chapter nieuwsbrief om op de hoogte te blijven van het laatste nieuws
                omtrent onze events en ontvang leuke en handige ondernemerstips!
              </p>
            </div>
            <div className="nieuwsbrief-form">
              <FormField className="form-field-instance" value={voornaam} placeholder="Voornaam*" onChange={handleVoornaamChange}/>
              <FormField className="form-field-instance" value={achternaam} placeholder="Achternaam*" onChange={handleAchternaamChange}/>
              <FormField className="form-field-instance" value={mail} placeholder="E-mailadres*" onChange={handleMailChange}/>
              <FormField className="form-field-instance" value={bedrijfsnaam} placeholder="Bedrijfsnaam" onChange={handleBedrijfsChange}/>
              <div className="enroll-button" onClick={submitForm}>
                <ButtonPrimary buttonText="AANMELDEN" className="button-primary-instance"/>
              </div>
              <div className="text-wrapper-5">*verplicht</div>
            </div>
          </div>
        </div>
        <div className="div-wrapper">
          <div className="div-2">
            <div className="div-3">
              <div className="werkboek-title-area">
                <div className="werkboek">WERKBOEK</div>
                <div className="vooruitkijken-naar">VOORUITKIJKEN NAAR &#39;24</div>
              </div>
              <div className="about-picture-line-2" />
              <p className="text-wrapper-4">
                2023. Zo’n jaar vliegt snel om. En voor je het weet, zal 2024 ook voorbij vliegen. Met dit werkboek kun
                je alvast nadenken over wat je in 2024 wilt doen. Wat ga je creëren, wat ga je doen? Welke doelen wil je
                behalen? Misschien heb je al doelen opgesteld voor het nieuwe jaar. Hartstikke goed! Ook de rest van het
                werkboek staat vol andere ideeën en opdrachten om mee aan de slag te gaan.
              </p>
            </div>
            <div className="about-button-area">
              <ButtonSecondary buttonText="DOWNLOADEN" className="design-component-instance-node" linkTo={'https://mega.nz/file/tqUVRJjD#AjaNiXwWGJHH8qufCQwvahHSbkzhO966nlCZ1QskYLE'} />
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="banner-content">
            <p className="aan-deze-site-wordt">AAN DEZE SITE WORDT GEWERKT</p>
            <div className="text-wrapper-6">Stay tuned for more...</div>
          </div>
        </div>
      </div>
    </div>
  );
};
